/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* global */
.none {display:none;}
.hidden {position: absolute; top: 0; left: -9999px; width: 0; height: 0; font-size: 0; line-height: 0; text-indent: -9999px; overflow: hidden;}
.fl {float:left;}
.fr {float:right;}
.left {text-align:left !important;}
.center {text-align:center !important;}
.right {text-align:right !important;}
.block {display:block;}
.inblock {display:inline-block;}
.clear:after {content:""; display:block; clear:both;}
.ellipsis {overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.bold { font-weight: bold !important;}
.underline { text-decoration: underline; }
.fc_white { color: white; }
.fc_red { color: black; }
.fs-10 { font-size: 10px; }
.fs-16 { font-size: 16px; }
.fs-15 { font-size: 15px; }
.fs-20 { font-size: 20px; }
.fs-25 { font-size: 25px; }
.fs-30 { font-size: 30px; }
.fs-35 { font-size: 35px; }
.fs-40 { font-size: 40px; }
.fs-45 { font-size: 45px; }
.fs-50 { font-size: 50px; }

.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-35 { margin-bottom: 35px; }
.mb-40 { margin-bottom: 40px; }
.mb-45 { margin-bottom: 45px; }
.mb-50 { margin-bottom: 50px; }

.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; }
.mt-45 { margin-top: 45px; }
.mt-50 { margin-top: 50px; }

.ml-10 { margin-left: 10px; }

.site_link_btn {
	width: 129.83px !important;
	height: 36px;
	text-align: center;
}

.accordion {
	margin:0 auto;
	max-width:540px;
}
.accordion__panel {
  animation: fadein 0.35s ease-in;
  text-align: center;
  padding: 0 15px 15px 15px;
}

.accordion__button:before {
  display: none;
}

.font-white{
  color: white;
}

.adm-card{
  min-height: 130px;
}

.btn_left_bar{
  position: relative;
}

.btn_left_bar::before{
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 20px;
  margin-top: -10px;
  background-color: #ddd;
}

.prev_icon {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 10px;
  height: 20px;
  padding: 20px 15px;
}

.prev_icon::before{
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -6px;
  width: 2px;
  height: 12px;
  background: #3387CC;
  transform: rotate(45deg);
}

.prev_icon::after{
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: 2px;
  width: 2px;
  height: 12px;
  background: #3387CC;
  transform: rotate(-45deg);
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}

.arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.spinner {
	-webkit-animation: spin 2000ms infinite linear;
	-moz-animation: spin 2000ms infinite linear;
	-ms-animation: spin 2000ms infinite linear;
	animation: spin 2000ms infinite linear;
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	100% {
		-ms-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.bg_s1_1{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3675a8+0,91a1cd+100 */
  background: rgb(54,117,168); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(54,117,168,1) 0%, rgba(145,161,205,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(54,117,168,1) 0%,rgba(145,161,205,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(54,117,168,1) 0%,rgba(145,161,205,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3675a8', endColorstr='#91a1cd',GradientType=0 ); /* IE6-9 */
}

.bg_s1_2{
  background-color: #F4F4F4;
}

.bg_s3_1{
  /* background: url(/images/wallpaper6.jpg); */
}

.bg_s3_2{
  background-color: #F4F4F4;
}

.bg_s4{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3675a8+0,91a1cd+100 */
  background: rgb(54,117,168); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(54,117,168,1) 0%, rgba(145,161,205,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(54,117,168,1) 0%,rgba(145,161,205,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(54,117,168,1) 0%,rgba(145,161,205,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3675a8', endColorstr='#91a1cd',GradientType=0 ); /* IE6-9 */
}

.bg_blue_gradient{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a0c9f5+0,b2bff1+100 */
  background: #a0c9f5; /* Old browsers */
  background: -moz-linear-gradient(top,  #a0c9f5 0%, #b2bff1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #a0c9f5 0%,#b2bff1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #a0c9f5 0%,#b2bff1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0c9f5', endColorstr='#b2bff1',GradientType=0 ); /* IE6-9 */
  overflow-y: auto;
  padding-bottom: 30px;
}

.bg_android_gradient{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#06071f+0,1d1d67+33,351b73+66,0a041c+100 */
  background: #06071f; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #06071f 0%, #1d1d67 33%, #351b73 66%, #0a041c 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #06071f 0%,#1d1d67 33%,#351b73 66%,#0a041c 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #06071f 0%,#1d1d67 33%,#351b73 66%,#0a041c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06071f', endColorstr='#0a041c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  overflow-y: auto;
  padding-bottom: 30px;
}

.Primary_BUTTON{
  border-radius: 20px;
  background: blue;
  width: 80px;
}

.Start__CONTAINER {
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.Start__HEADER__CONTAINER {
  position: absolute;
  width: 100%;
  top: 47%;
  right: 50%;
  transform: translate(50%,-50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Start__HEADER__TEXT {
  font-size: 31px;
  color: #000000;
}

.Start__SUBHEADER__TEXT {
  font-size: 18px;
  margin-top: 10px;
  font-weight: normal;
  color: #000000;
}

.Start__CONTENT__CONTAINER{
  position: absolute;
  bottom: 88px;
  left: 0;
  right: 0;;
  text-align: center;
}

.Start__CONTENT__CONTAINER .ant-btn{
  background-color: #256de1;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  width: 138px;
  height: 39px;
  color: #ffffff;
}

.Start__FOOTER__CONTAINER{
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  font-size: 9px;
  text-align: center;
  color: #000000;
}

.Start__FOOTER__TEXT{
  font-size: 10px;
}

.ScenarioList__CONTAINER{
  padding-top: 65px;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.ScenarioList__HEADER__CONTAINER {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.ScenarioList__HEADER__TEXT{
  color: #000000;
  font-size: 28px;
}

.ScenarioList__SUBHEADER__TEXT{
  font-size: 15px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 200px;
  word-break: keep-all;
}

.ScenarioList__CONTENT__CONTAINER {
  padding: 0 20px
}

.ScenarioList__CONTENT__CONTAINER .top_btn_container{
  margin: 0 auto;
	max-width: 540px;
}

.ScenarioList__CONTENT__CONTAINER .top_btn_container .btn{
  text-decoration: underline;
	color: #000;
	font-size: 13px;
}

.ScenarioList__CONTENT__CONTAINER .accordion__heading {
  padding: 12px 23px 11px 13px;
}

.ScenarioList__CONTENT__CONTAINER .accordion__heading img{
  border-radius: 9px;
}

.ScenarioList__CONTENT__CONTAINER .accordion_top{
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}

.ScenarioList__CONTENT__CONTAINER .accordion_top .img_box{

}

.ScenarioList__CONTENT__CONTAINER .accordion_top .txt_box{
	padding-left:15px;

}

.ScenarioList__CONTENT__CONTAINER .txt_box .tit {
  font-size: 15px;
  padding: 5px 0 0;
  word-break: keep-all;
  text-align: right;
  font-weight: bold;
}

.ScenarioList__CONTENT__CONTAINER .txt_box .tit_des {
  font-size: 10px;
  margin-top: 10px;
	padding-bottom:20px;
  word-break: keep-all;
  text-align: right;
}

.ScenarioList__CONTENT__CONTAINER .accordion_top .arrow{
  position: absolute;
  bottom: 3px;
  right: 0;
}

.ScenarioList__CONTENT__CONTAINER .accordion__item{
  position: relative;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 13px;
}

.ScenarioList__CONTENT__CONTAINER .accordion__panel  {
  position: relative;
  text-align: right;
}

.ScenarioList__CONTENT__CONTAINER .accordion__panel .txt{
  color: #256de2;
  font-size: 10px;
  padding:15px 5px 0 0;
  margin-bottom: 8px;
  line-height:130%;
  border-top: 1px solid #7f7f7f;
}

.ScenarioList__CONTENT__CONTAINER .accordion__panel .btn{
  font-size: 10px;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  background-color: #256DE3;
  width: 77px;
  height: 23px;
}

.ScenarioList__CONTENT__CONTAINER .accordion__panel .btn:active{
  background-color: #79a4ec;
}

.ScenarioThumbnail{
  padding-top: 63px;
  box-sizing: border-box;
  height: 100vh;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
}
.ScenarioThumbnail.s1{
  background: url(@/assets/images/thumbnail_s1.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s2{
  background: url(@/assets/images/thumbnail_s2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s3{
  background: url(@/assets/images/thumbnail_s3.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s4{
  background: url(@/assets/images/thumbnail_s4.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s5{
  background: url(@/assets/images/thumbnail_s5.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s6{
  background: url(@/assets/images/thumbnail_s6.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s7{
  background: url(@/assets/images/thumbnail_s7.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s8{
  background: url(@/assets/images/thumbnail_s8.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ScenarioThumbnail.s9{
  background: url(@/assets/images/thumbnail_s9.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ScenarioThumbnail_dimm{
	display: none;
}

.ScenarioThumbnail_dimm.active{
	display: block;
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
	background: url(@/assets/images/dimm_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ScenarioThumbnail__HEADER__CONTAINER {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.ScenarioThumbnail__HEADER__TEXT {
  font-size: 28px;
  color: #ffffff;
}

.ScenarioThumbnail__CONTENT__CONTAINER {
  position: absolute;
  width: 80%;
  max-width:360px;
  top: 306.3px;
  right: 50%;
  transform: translateX(50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ScenarioThumbnail__SUBHEADER__TEXT {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  display:inline-block;
  border-bottom:1px solid #fff;
}

.ScenarioThumbnail__CONTENT__TEXT {
  font-size: 20px;
  color: #ffffff;
  word-break: keep-all;
  margin-top: 30px;
}

.ScenarioThumbnail__BUTTON__CONTAINER {
  position: absolute;
	display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 235px;
  max-width:235px;
  top: 478.5px;
  right: 50%;
  transform: translateX(50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ScenarioThumbnail__BUTTON__CONTAINER .ant-btn{
  background-color: #ffffff;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  width: 112.5px;
  height: 36px;
}

.ScenarioThumbnail__FOOTER__CONTAINER {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 43px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ScenarioThumbnail__FOOTER__TEXT {
  font-size: 10px;
  color: #ffffff;
}

.ScenarioThumbnail_modal {
	position: absolute;
	top: -100%;
	left: 50%;
	width: calc(100% - 50px);
	max-width: 490px;
	transform: translateX(-50%);
	background: #fff;
	border-radius: 20px;
	padding: 20px 30px 20px 82px;
	transition: all 0.8s ease-in-out;
	opacity: 0;
	z-index: 100;
}

.ScenarioThumbnail_modal .icon_tap_gesture{
	opacity: 0;
	position: absolute;
	width: 85px;
	/* height: 50px; */
	bottom: -60px;
	left: 50%;
	text-align: center;
	transform: translateX(-50%);
}

.ScenarioThumbnail_modal.on .icon_tap_gesture{
	animation: show 1s ease-in-out forwards;
	animation-delay: 1.1s
}

@keyframes show{
  to{
    opacity: 1;
		bottom: -45px;
  }
}

.ScenarioThumbnail_modal .icon_circle{
	position: absolute;
	width: 30px;
	height: 30px;
	top: 50px;
	left: 30px;
	border-radius: 50%;
	border: 5px solid #7AA5ED;
	transform: translateY(-50%);
}

.ScenarioThumbnail_modal .icon_right_arrow{
	position: absolute;
	width: 30px;
	height: 30px;
	top: 50px;
	left: 30px;
	transform: translateY(-50%);
}

.ScenarioThumbnail_modal .icon_right_arrow img{
	width: 30px;
	height: 30px;
}

.ScenarioThumbnail_modal.on {
	top: 20px;
	opacity: 1;
}

.ScenarioThumbnail_modal h3{
	margin-bottom: 6px;
	line-height: 26px;
	font-size: 24px;
	font-weight: bold;
}

.ScenarioThumbnail_modal p{
	line-height: 16px;
	font-size: 14px;
}

.InteractionScenario1Step1__CONTAINER {
  padding-top: 65px;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}
.InteractionScenario1Step1__HEADER__CONTAINER {
  text-align: center;
}
.InteractionScenario1Step1__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}
.InteractionScenario1Step1__SUBHEADER__TEXT{
  font-size: 15px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 156px;
  word-break: keep-all;
}
.InteractionScenario1Step1__CONTENT__CONTAINER {
  padding: 30px 20px;
}
.InteractionScenario1Step1__CONTENT__CONTAINER .ant-card{
  margin-top: 10px;
  border-radius: 13px;
  height: 120px;
  margin-bottom: 4px;
}
.InteractionScenario1Step1__CONTENT__CONTAINER .ant-card-body{
  padding: 12px;
}
.InteractionScenario1Step1__CONTENT__CONTAINER .ant-card .status{
  color: #c8c8c8;
}
.InteractionScenario1Step1__CONTENT__CONTAINER .ant-card.active{
  opacity:1.0
}
.InteractionScenario1Step1__CONTENT__CONTAINER .ant-card.inactive{
  opacity:0.5
}
.InteractionScenario1Step1__FOOTER__CONTAINER {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px 25px
}
.InteractionScenario1Step1__FOOTER__CONTAINER .ant-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.InteractionScenario1Step1__FOOTER__CONTAINER .ant-row .ant-col.active {
  opacity: 1.0;
}
.InteractionScenario1Step1__FOOTER__CONTAINER .ant-row .ant-col.inactive {
  opacity: 0.6;
}
.InteractionScenario1Step1__FOOTER__CONTAINER .ant-row .ant-col img {
  width: 26px;
}
.InteractionScenario1Step1__FOOTER__CONTAINER .ant-row .ant-col p {
  font-size: 12px;
  color: #fff;
  margin-top: 8px;
}
.InteractionScenario1Step2__CONTAINER {
  padding-top: 10px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}
.InteractionScenario1Step2__HEADER__CONTAINER {
  position: relative;
  color: white;
  font-weight: bold;
  padding: 5px 20px 0 50px;
}
.InteractionScenario1Step2__HEADER__PREV__LINK {
  position: absolute;
  top: 0;
  left: 0;
}
.InteractionScenario1Step2__CONTENT__HEADER__TEXT {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.InteractionScenario1Step2__CONTENT__SUBHEADER__TEXT {
  color: #787878;
  font-size:15px;
}
.InteractionScenario1Step2__CONTENT__CONTAINER {
  padding: 12px 20px;
}
.InteractionScenario1Step2__CONTENT__CONTAINER .ant-card {
  position: relative;
  padding: 0 10px;
  border: 1px solid #EDEDED;
  border-radius: 13px;
  margin-bottom: 8px;
}
.InteractionScenario1Step2__CONTENT__CONTAINER .ant-card-body{
  padding: 0;
}
.InteractionScenario1Step2__CONTENT__CARD__HEADER__CONTAINER {
  position: relative;
  padding-left: 60px;
}
.InteractionScenario1Step2__CONTENT__CARD__HEADER__CONTENT__CONTAINER {
  padding: 15px 0;
}
.InteractionScenario1Step2__CONTENT__CARD__HEADER__CONTENT__HEADER__TEXT {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}
.InteractionScenario1Step2__CONTENT__CARD__HEADER__CONTENT__DESCRIPTION__TEXT {
  font-size: 10px;
  color: #B4B4B4;
  line-height: 15px;
}
.InteractionScenario1Step2__CONTENT__PHOTO__ZONE {
  position: relative;
  min-height: 50px;
  padding: 15px 0;
  text-align: center;
}
.InteractionScenario1Step2__CONTENT__PHOTO__ZONE::before{
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 60px);
  height: 1px;
  background: #EDEDED
}

.InteractionScenario2__CONTAINER {
  background: #06071f; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #06071f 0%, #1d1d67 33%, #351b73 66%, #0a041c 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #06071f 0%,#1d1d67 33%,#351b73 66%,#0a041c 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #06071f 0%,#1d1d67 33%,#351b73 66%,#0a041c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06071f', endColorstr='#0a041c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  overflow-y: auto;
  box-sizing: border-box;
  height: 100vh;
}

.InteractionScenario2__CONTENT__CONTAINER {
  position: absolute;
  width: 100%;
  bottom: 20px;
  text-align: center;
}

.InteractionScenario2__CONTENT__CONTAINER .ant-card {
  border-radius: 13px;
  overflow: hidden;
  text-align: center;
}

.InteractionScenario2__CONTENT__CONTAINER .ant-card-body {
  padding: 0;
}

.InteractionScenario2__CONTENT__CONTAINER .ant-card-body .InteractionScenario2__CONTENT__TITLE {
  padding: 40px 30px 20px;
  font-size: 15px;
  word-break: keep-all;
}

.InteractionScenario2__CONTENT__CONTAINER .ant-card-body .InteractionScenario2__CONTENT__BUTTON__CONTAINER {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.InteractionScenario2__CONTENT__CONTAINER .ant-card-body .InteractionScenario2__CONTENT__BUTTON__CONTAINER .ant-btn{
  width: 50%;
  color: #1175DE;
  font-size: 14px;
  border: none;
  height: 50px;
}

.InteractionScenario2__ICON__CONTAINER {
  position: absolute;
  top: 25%;
  left: 30px;
  text-align: center;
}

.InteractionScenario2__ICON__CONTAINER img {
  width: 50px;
}

.InteractionScenario2__ICON__CONTAINER p {
  font-size: 12px;
  margin-top: 5px;
}

.InteractionScenario3Step1__CONTAINER {
  padding-top: 65px;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}
.InteractionScenario3Step1__HEADER__CONTAINER {
  text-align: center;
}
.InteractionScenario3Step1__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}
.InteractionScenario3Step1__SUBHEADER__TEXT{
  font-size: 15px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 156px;
  word-break: keep-all;
}
.InteractionScenario3Step1__CONTENT__CONTAINER {
  padding: 30px 20px;
}
.InteractionScenario3Step1__CONTENT__CONTAINER .ant-card{
  margin-top: 10px;
  border-radius: 13px;
  height: 120px;
  margin-bottom: 4px;
}
.InteractionScenario3Step1__CONTENT__CONTAINER .ant-card-body{
  padding: 12px;
}
.InteractionScenario3Step1__CONTENT__CONTAINER .ant-card .status{
  color: #c8c8c8;
}
.InteractionScenario3Step1__CONTENT__CONTAINER .ant-card.active{
  opacity:1.0
}
.InteractionScenario3Step1__CONTENT__CONTAINER .ant-card.inactive{
  opacity:0.5
}
.InteractionScenario3Step1__FOOTER__CONTAINER {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px 25px
}
.InteractionScenario3Step1__FOOTER__CONTAINER .ant-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.InteractionScenario3Step1__FOOTER__CONTAINER .ant-row .ant-col.active {
  opacity: 1.0;
}
.InteractionScenario3Step1__FOOTER__CONTAINER .ant-row .ant-col.inactive {
  opacity: 0.6;
}
.InteractionScenario3Step1__FOOTER__CONTAINER .ant-row .ant-col img {
  width: 26px;
}
.InteractionScenario3Step1__FOOTER__CONTAINER .ant-row .ant-col p {
  font-size: 12px;
  color: #fff;
  margin-top: 8px;
}
.InteractionScenario3Step2__CONTAINER {
  padding-top: 10px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}
.InteractionScenario3Step2__HEADER__CONTAINER {
  position: relative;
  color: white;
  font-weight: bold;
  padding: 5px 20px 0 50px;
}
.InteractionScenario3Step2__HEADER__PREV__LINK {
  position: absolute;
  top: 0;
  left: 0;
}
.InteractionScenario3Step2__CONTENT__HEADER__TEXT {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.InteractionScenario3Step2__CONTENT__SUBHEADER__TEXT {
  color: #787878;
  font-size:15px;
}
.InteractionScenario3Step2__CONTENT__CONTAINER {
  padding: 12px 20px;
}
.InteractionScenario3Step2__CONTENT__CONTAINER .ant-card {
  position: relative;
  padding: 0 10px;
  border: 1px solid #EDEDED;
  border-radius: 13px;
  margin-bottom: 8px;
}
.InteractionScenario3Step2__CONTENT__CONTAINER .ant-card-body{
  padding: 0;
}
.InteractionScenario3Step2__CONTENT__CARD__HEADER__CONTAINER {
  position: relative;
  padding-left: 60px;
}
.InteractionScenario3Step2__CONTENT__CARD__HEADER__CONTENT__CONTAINER {
  padding: 15px 0;
}
.InteractionScenario3Step2__CONTENT__CARD__HEADER__CONTENT__HEADER__TEXT {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}
.InteractionScenario3Step2__CONTENT__CARD__HEADER__CONTENT__DESCRIPTION__TEXT {
  font-size: 10px;
  color: #B4B4B4;
  line-height: 15px;
}
.InteractionScenario3Step2__CONTENT__PHOTO__ZONE {
  position: relative;
  min-height: 50px;
  padding: 15px 0;
  text-align: center;
}
.InteractionScenario3Step2__CONTENT__PHOTO__ZONE::before{
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 60px);
  height: 1px;
  background: #EDEDED
}

.InteractionScenario4__CONTAINER {
  padding-top: 65px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.InteractionScenario4__HEADER__CONTAINER {
  text-align: center;
}

.InteractionScenario4__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}

.InteractionScenario4__SUBHEADER__TEXT{
  font-size: 15px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 156px;
  word-break: keep-all;
}

.InteractionScenario4__CONTENT__CONTAINER {
  padding: 30px 20px;
}

.InteractionScenario4__CONTENT__CONTAINER .ant-card {
  position: relative;
  border: 1px solid #EDEDED;
  border-radius: 13px;
  margin-bottom: 8px;
}

.InteractionScenario4__CONTENT__CONTAINER .ant-card-body{
  position: relative;
  padding: 12px;
}

.InteractionScenario4__CONTENT__CONTAINER .InteractionScenario4__CONTENT__HEADER .txt {
  font-size: 15px;
  font-weight: bold;
}

.InteractionScenario4__CONTENT__CONTAINER .InteractionScenario4__CONTENT__HEADER .arrow_box {
  position: absolute;
  right: 12px;
  top: 12px;
}

.InteractionScenario4__CONTENT__CONTAINER .InteractionScenario4__CONTENT__HEADER .arrow {
  padding: 3px;
}

.InteractionScenario4__CONTENT__CONTAINER .InteractionScenario4__CONTENT__HEADER .desc {
  font-size: 10px;
}

.InteractionScenario4__CONTENT__CARD__HEADER__CONTENT__DESCRIPTION__TEXT {
  font-size: 10px;
  color: #B4B4B4;
  line-height: 15px;
}
.InteractionScenario4__CONTENT__PHOTO__ZONE {
  position: relative;
  min-height: 50px;
  padding: 15px 0;
  text-align: center;
}
.InteractionScenario4__CONTENT__PHOTO__ZONE::before{
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 60px);
  height: 1px;
  background: #EDEDED
}

/*

.InteractionScenario4__CONTENT__LIST .ant-card-body{
  padding: 0;
} */

.InteractionScenario5__CONTAINER {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#06071f+0,1d1d67+33,351b73+66,0a041c+100 */
  background: #06071f; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #06071f 0%, #1d1d67 33%, #351b73 66%, #0a041c 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #06071f 0%,#1d1d67 33%,#351b73 66%,#0a041c 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #06071f 0%,#1d1d67 33%,#351b73 66%,#0a041c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06071f', endColorstr='#0a041c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  box-sizing: border-box;
  height: 100vh;
  overflow-y:auto;
}

.InteractionScenario5__CONTAINER .icon_box{
  position: absolute;
  top: 25%;
  left: 30px;
  text-align: center;
}

.InteractionScenario5__CONTAINER .icon_box .icon{
  width: 50px;
}

.InteractionScenario5__CONTAINER .icon_box .icon_txt{
  color: #ffffff;
  font-size: 12px;
  margin-top: 5px;
}

.InteractionScenario5__FOOTER__CONTAINER {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  background-color: #000;
  padding: 7px 0;
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_1 {
  position: relative;
  width: 20px;
  height: 10px;
  text-align: center;
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_1 span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 2px;
  height: 12px;
  background: #fff;
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_1 span::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  width: 2px;
  height: 12px;
  background: #fff;
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_1 span::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  width: 2px;
  height: 12px;
  background: #fff;
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_2 {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 3px;
}


.InteractionScenario5__FOOTER__CONTAINER .android_icon_3 {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 10px;
  height: 12px;
  padding: 20px 15px;
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_3::before{
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -6px;
  width: 2px;
  height: 10px;
  background: #fff;
  transform: rotate(45deg);
}

.InteractionScenario5__FOOTER__CONTAINER .android_icon_3::after{
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: 0px;
  width: 2px;
  height: 10px;
  background: #fff;
  transform: rotate(-45deg);
}

.new_page_container {
    width:100%;
    height:100vh;
    background: #f5f5f5;
}
.new_page_container .dark_screen {
    position: fixed;
    z-index:1;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0, 0.5);
}
.new_page_header {
    position: absolute;
    top:8vw;
}
.new_page_header > img {
    width:100%;
}
.new_page_body {
    position:relative;
    top: calc(0 - 8vw);
    height:100%;
    height:100%;
}
.new_page_body .inner_2 {
    position: absolute;
    bottom:0;
}
.new_page_body .inner_2 > img {
    width:100%;
}
.new_page_body .inner_1 {
    position: absolute;
    bottom:28.75vw;
    left:55.5vw;
    z-index:2;
}
.new_page_body .inner_1 > img {
    width:11vw;
}
.new_page_body .inner_0 {
    position: absolute;
    bottom:11vw;
    left:53vw;
    z-index:3;
}
.new_page_body .inner_0 > img {
    width:23vw;
}

/***********************************
3번째 페이지
***********************************/
.smartview_page_container {
    position: fixed;
    width:100vw;
    height:100vh;
    background: #f5f5f5;
}
.smartview_page_container .dark_screen {
    position: fixed;
		top: 0;
		left: 0;
    z-index:1;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0, 0.5);
}
.smartview_page_header {
    position: absolute;
    top:9.3vw;
    padding:0 7vw;
    width:100%;
    text-align:right;
}
.smartview_page_header > img {
    margin:0 1.9vw;
    width:4.7vw;
}


.smartview_page_body {
    position:fixed;
    bottom:0;
}
.smartview_container {

}

.smartview_tit {
    padding:5vw 3vw 10vw 3vw;
}
.smartview_tit ul:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: "";
}
.smartview_tit li {
    float:left;
    width: calc(50% - 1.8vw);
    text-align:center;
    line-height:12vw;
    height:12vw;
    font-size:1em;
    border-radius:20vw;
    background:#e7e7e7;
}
.smartview_tit li:nth-child(2) {
    float:right;
}

.smartview_icon {
    position: relative;
    bottom:0;
    width:100%;
}
.smartview_icon > ul {
    margin:0 5.5vw;
}
.smartview_icon > ul:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: "";
}
.smartview_icon > ul > li {
    float:left;
    width:25%;

    text-align:center;
}
.smartview_icon > ul > li  img {
    width:46.5%;
    margin-bottom:3.2vw;
}
.smartview_icon > ul > li .inner_tx {
    margin:0 auto;
    width:80%;
    font-size:0.8em;
    min-height:12vw;
}
.smartview_next {
    padding: 0 0 4vw 0;
    width:100%;
    text-align:center;
}
.smartview_next img {
    width:4vw;
}
.smartview_bar {
    padding-bottom:8vw;
    width:100%;
    text-align:center;
}
.smartview_bar img {
    width:95%;
}
.smartview_btn {
    position: fixed;
    z-index: 2;
    right: calc(25% + 5.15vw);
    bottom: 40vw;
    display: inline-block;
    width: calc(25% - 11vw);
}
.smartview_btn img.icon11 {
    width:74%;
}
.smartview_btn .inner_over {
    position: absolute;
    z-index:99;
}
.smartview_btn .tap_gesture {
    position: absolute;
    z-index:33;
    width:20vw;
    top:3vw;
    left:-1vw;
}


/***********************************
5번째 페이지 시작
***********************************/
.smartview_page5_container {
    position: fixed;
    width:100vw;
    height:100vh;
		background: url(@/assets/images/s5/bg.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
}

.smartview_page5_container .dark_screen {
    position: fixed;
		top: 0;
		left: 0;
    z-index:1;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0, 0.5);
}

.smartview_page5_body {
	position: absolute;
	bottom: 50px;
	left: 10px;
	width: calc(100% - 20px);
	height: 215px;
	background-color: #fff;
	border-radius: 20px;
}

.smartview_page5_body h5 { margin: 25px 0 0 15px; color: #000; line-height: 22px; font-size: 20px; font-weight: bold;}
.smartview_page5_body .smartview_page5_icon {margin-top: 30px; text-align: center; }
.smartview_page5_body .smartview_page5_icon img { width: 230px; }
.smartview_page5_body .btn_box {	display: flex; flex-wrap: nowrap; align-items: center; justify-content: space-around; margin: 15px 0; width: 100%;}
.smartview_page5_body .btn_box button {  width: 94px; height: 34px; border: none; background-color: transparent; background-repeat: no-repeat; border: none; cursor: pointer; overflow: hidden; outline: none; color: #3e7ed6; line-height: 17px; font-size: 15px; }

.smartview_page5_footer{
	width: 100%;
	position: absolute;
	bottom: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-around;
	padding: 0 70px 13px;
}

.smartview_page5_footer img {
	width: 20px;
	height: 20px;
	margin-left: 90px;
}

.smartview_page5_footer img:first-child { margin-left: 0; }

/***********************************
5번째 페이지 끝
***********************************/

/***********************************
6번째 페이지
***********************************/
.smartview_page6_container {
    position: fixed;
    width:100vw;
    height:100vh;
		background: url(@/assets/images/s9/bg_samsung.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
}


/* .ScenarioThumbnail{
  padding-top: 63px;
  box-sizing: border-box;
  height: 100vh;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
} */

.smartview_page6_container .dark_screen {
    position: fixed;
		top: 0;
		left: 0;
    z-index:1;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0, 0.5);
}
.smartview_page6_header{
  margin: 100px 5.5vw 0;
	text-align: center;
}

.smartview_page6_header p{
	display: inline-block;
	margin: 0 auto;
	color: #fff;
	font-weight: 700;
	line-height: 47px;
	font-size: 45px;
}

.smartview_page6_body {
    /* position:fixed;
    bottom:0; */
}
.smartview_container {

}

.smartview_page6_tit {
	padding-top: 50px;
}
.smartview_page6_tit > ul {
  margin:0 5.5vw;
	padding-right: 33px;
	padding-bottom: 15px;
}
.smartview_page6_tit ul:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.smartview_page6_tit li {
  float:right;
  text-align:center;
	padding-left: 20px;
}
.smartview_page6_tit li img {
  width: 20px;
	height: 20px;
}
.smartview_page6_tit li:nth-child(2) {
  float:right;
}

.smartview_page6_icon {
    position: relative;
    bottom:0;
    width:100%;
}
.smartview_page6_icon > ul {
    margin:0 5.5vw;
		height: calc(100vh - 310px);
		padding-top: 23px;
		background: rgba(255,255,255, 0.2);
		border-radius: 30px;
}
.smartview_page6_icon > ul:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: "";
}
.smartview_page6_icon > ul > li {
    float:left;
    width:33%;

    text-align:center;
}
.smartview_page6_icon > ul > li  img {
    width:46.5%;
    margin-bottom:3.2vw;
}
.smartview_page6_icon > ul > li .inner_tx {
    margin:0 auto;
    width:80%;
    font-size:0.8em;
    min-height:12vw;
		color: #fff;
}
.smartview_page6_next {
    padding: 0 0 4vw 0;
    width:100%;
    text-align:center;
}
.smartview_page6_next img {
    width:4vw;
}
.smartview_page6_bar {
    padding-bottom:8vw;
    width:100%;
    text-align:center;
}
.smartview_page6_bar img {
    width:95%;
}
.smartview_page6_btn {
    position: fixed;
    z-index: 2;
    right: calc(25% + 5.15vw);
    bottom: 40vw;
    display: inline-block;
    width: calc(25% - 11vw);
}
.smartview_page6_btn img.icon11 {
    width:74%;
}
.smartview_page6_btn .inner_over {
    position: absolute;
    z-index:99;
}
.smartview_page6_btn .tap_gesture {
    position: absolute;
    z-index:33;
    width:20vw;
    top:3vw;
    left:-1vw;
}


.smartview_page6_footer{
	width: 100%;
	position: absolute;
	bottom: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-around;
	padding: 0 70px 13px;
}

.smartview_page6_footer img {
	width: 20px;
	height: 20px;
}

.InteractionScenario7__CONTAINER .dark_screen {
    position: fixed;
    z-index:1;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0, 0.5);
}
.InteractionScenario7__CONTAINER .btn_AirConditioning {
    position: absolute;
    z-index:3;
    width: 44.7%;
}
.InteractionScenario7__CONTAINER .bg_img {
    width:100%;
    height: 100vh;
    font-size:3.38vw;
		box-sizing: border-box;
    font-family: 'SamsungOne';
    background: url(@/assets/images/s7/bg.jpg) no-repeat 0 0;
    background-size:cover;
}
.InteractionScenario7__CONTAINER .header_container {
    position: relative;
    top:8vw;
    display: flex;
    width:100%;
    padding:0 3.5vw 0 5.6vw;
}

.InteractionScenario7__CONTAINER .header_container > div {
    width:50%;
    height:7vw;
}
.InteractionScenario7__CONTAINER .header_container .inner_le img {
    width:5.3vw;
    margin-right:2.6vw;
}
.InteractionScenario7__CONTAINER .header_container .inner_le span {
    font-size:1.7em;
    font-family: 'SamsungOne';
		font-weight: bold;
    color:#fff;
    letter-spacing: -0.025em;
}
.InteractionScenario7__CONTAINER .header_container .inner_ri {
    text-align:right;
}
.InteractionScenario7__CONTAINER .header_container .inner_ri img {
    width:5.3vw;
}
.InteractionScenario7__CONTAINER .header_container .inner_ri img:nth-child(2) {
    margin:0 4.7vw;
}



.InteractionScenario7__CONTAINER .body_container {
    position: relative;
    width:100%;
    top:20vw;
    padding:3.5vw;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr .inner_title {
    font-family: 'SamsungOne';
		font-weight: bold;
    color:#fff;
    font-size:2.6em;
    text-indent:2.4vw;
    margin-bottom:12vw;
    letter-spacing: -0.03em;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr .inner_scenes {
    padding:0 3vw 0 2.5vw;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr .inner_scenes:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: "";
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr .inner_scenes .inner_1 {
    font-family: 'SamsungOne';
		font-weight: bold;
    color:#fff;
    font-size:1.2em;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr .inner_scenes .inner_2 {
    display:inline-block;
    float: right;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr .inner_scenes .inner_2 img {
    position: relative;
    top:-0.6vw;
    width:5vw;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr ul {
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr  ul:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: "";
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr > ul > li {
    float:left;
    width: calc(50% - 1.8vw);
    margin-bottom:5vw;
}
.InteractionScenario7__CONTAINER .body_container .scenes_contaienr > ul > li:nth-child(2n-2) {
    float:right;
}
/***/
.InteractionScenario7__CONTAINER .scenes_contaienr_box {
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul {
    padding:3vw 2vw 3vw 3vw;
    background:#fff;
    border-radius:5vw;
}

.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li {
    float:left;
    height:10vw;
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(1) img  {
    margin:2vw 0 0 1vw;
    width:6vw;
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(2) {
    position: relative;
    background: rgba(0,0,0, 0.3);
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(2) .icon_hand {
    position: absolute;
    top:6vw;
    left:7.2vw;
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(2) .icon_hand > img {
    width:12vw;
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(2) .inner_tx {
    position: absolute;
    top:50%;
    left:2vw;
    display:inline-block;
    width:100%;
    min-width:20vw;
    line-height:120%;
    transform: translate(0, -50%);
    font-family: 'SamsungOne';
		font-weight: bold;
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(3) {
    float:right;
}
.InteractionScenario7__CONTAINER .scenes_contaienr_box ul > li:nth-child(3) img {
    width:9.8vw;
}
/***/

.InteractionScenario7__CONTAINER .footer_contianer {
    position: absolute;
    bottom:4vw;
    width:100%;
}
.InteractionScenario7__CONTAINER .footer_contianer ul:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: "";
}
.InteractionScenario7__CONTAINER .footer_contianer ul > li {
    float:left;
    width:20%;
    text-align:center;
    opacity: 1;
}
.InteractionScenario7__CONTAINER .footer_contianer ul > li > img {
    width:5vw;
    margin-bottom:2vw;
}
.InteractionScenario7__CONTAINER .footer_contianer ul > li > p {
    font-size:0.95em;
    color:#fff;
    letter-spacing: -0.025em;
}

.End__CONTAINER{
  padding-top: 65px;
  padding-bottom: 45px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.End__HEADER__CONTAINER {
  text-align: center;
}

.End__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}

.End__SUBHEADER__TEXT {
  color: #000000;
  font-size: 15px;
  margin-top: 10px;
}

.End__CONTENT__CONTAINER{
  position: absolute;
  top: 50%;
  right: 50%;
  width: 270px;
  transform: translate(50%,-50%);
  text-align: center;
}

.End__CONTENT__CONTAINER .txt{
  color: #000000;
  font-size: 15px;
}

.End__CONTENT__CONTAINER .btn_box{
  margin-top: 50px;
}

.End__CONTENT__CONTAINER .btn_box .ant-btn{
  color: #ffffff;
  font-size: 14px;
  border-radius: 20px;
  border: none;
  background: #256de1;
  width: 113px;
  height: 38px;
}

.End__CONTENT__CONTAINER .btn_box .ant-btn.black{
  background: #000000;
}

.End__CONTENT__CONTAINER .btn_box .ant-btn:active{
  color: #000000;
  background: #ffffff;
}

.End__BUTTON__CONTAINER{
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.End__FOOTER__CONTAINER{
  position: absolute;
  bottom: 43px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.End__FOOTER__TEXT{
  color: #000000;
  font-size: 10px;
}

.NotFound__CONTAINER{
  padding-top: 65px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.NotFound__HEADER__CONTAINER {
  text-align: center;
}

.NotFound__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}

.NotFound__CONTENT__CONTAINER{
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.NotFound__CONTENT__CONTAINER .txt {
  color: #000000;
  font-size: 15px;
  margin-top: 27px;
}

.NotFound__CONTENT__CONTAINER .btn_box{
  margin-top: 37px;
}

.NotFound__CONTENT__CONTAINER .btn_box .ant-btn{
  color: #ffffff;
  font-size: 14px;
  border-radius: 20px;
  border: none;
  background: #256de1;
  width: 113px;
  height: 36px;
}

.NotFound__FOOTER__CONTAINER{
  position: absolute;
  bottom: 43px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.NotFound__FOOTER__TEXT{
  color: #000000;
  font-size: 10px;
}

.Error__CONTAINER{
  padding-top: 65px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.Error__HEADER__CONTAINER {
  text-align: center;
}

.Error__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}

.Error__SUBHEADER__TEXT{
  font-size: 15px;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 30px;
  width: 180px;
  word-break: keep-all;
}


.Error__CONTENT__CONTAINER{
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Error__CONTENT__CONTAINER .btn_box{
  margin-top: 37px;
}

.Error__CONTENT__CONTAINER .btn_box .ant-btn{
  color: #ffffff;
  font-size: 14px;
  border-radius: 20px;
  border: none;
  background: #256de1;
  width: 113px;
  height: 38px;
}

.Error__CONTENT__CONTAINER .btn_box .ant-btn:active{
  color: #000000;
  background: #ffffff;
}

.Error__CONTENT__CONTAINER .anticon-exclamation-circle {
  color: #ffffff;
  font-size: 60px;
}

.Error__CONTENT__CONTAINER .txt {
  color: #000000;
  font-size: 15px;
  margin-top: 27px;
}

.Error__CONTENT__CONTAINER .btn_box {

}

.Error__FOOTER__CONTAINER{
  position: absolute;
  bottom: 43px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Error__FOOTER__TEXT{
  color: #000000;
  font-size: 10px;
}

.Loading__CONTAINER{
  padding-top: 65px;
  box-sizing: border-box;
  height: 100vh;
  background: url(@/assets/images/bg.png);
  background-size: cover;
  overflow-y: auto;
}

.Loading__HEADER__CONTAINER {
  text-align: center;
}

.Loading__HEADER__TEXT {
  color: #000000;
  font-size: 28px;
}

.Loading__CONTENT__CONTAINER{
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Loading__CONTENT__CONTAINER .txt {
  color: #000000;
  font-size: 15px;
  margin-top: 25px;
}

.Loading__FOOTER__CONTAINER{
  position: absolute;
  bottom: 44px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.Loading__FOOTER__TEXT{
  color: #000000;
  font-size: 10px;
}

.modal_container{
	position: fixed;
	bottom: 48px;
	width: calc(100% - 40px);
	max-width:540px;
	height: 74%;
	z-index: 10;
	right: 50%;
	transform: translateX(50%);
	background: #fff;
	border-radius: 6px;
}

.modal_container .modal_body{
	/* padding: 10px; */
	padding: 27px 20px;
	width: 100%;
	height: 100%;
}

.modal_container .modal_body .message{
	height: calc(100% - 80px);
	padding: 6px 22px;
	line-height: 18px;
	font-size: 10px;
	overflow-y: auto;
	margin-bottom: 40px;
	white-space: pre-wrap;
}

.modal_container .modal_footer{
	/* padding: 10px; */
	bottom: 20px;
	/* width: 100%; */
	height: auto;
	text-align: center;
}

.modal_container .modal_footer .ant-btn{
  background-color: #000;
  font-size: 13px;
  border: none;
  border-radius: 30px;
  width: 110px;
  height: 35px;
  color: #ffffff;
}

.ScenarioThumbnail__BUTTON__CONTAINER .ant-btn, .ScenarioThumbnail__BUTTON__CONTAINER .ant-btn:active, .ScenarioThumbnail__BUTTON__CONTAINER .ant-btn:focus {
	color: #000 !important;
}

@media (max-width: 280px) {
  .ScenarioList__CONTENT__CONTAINER, .modal_container{
    padding: 0 15px;
  }
}

@media (min-width: 570px) {
	.modal_container {
    padding: 0;
		height: 78%;
  }
}
